import React, {useEffect, useState, useRef } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import queryString from 'query-string'
import PropTypes from 'prop-types'

import { Button } from 'components/Forms'
import { Button as Btn } from '@pet-notify/ui'
import {
  Base,
  Header,
  Text as Txt,
  Link as Lnk,
  PetPhoto,
  Modal as Mod,
  Loader,
} from '@pet-notify/ui'
import ButtonGroup from 'components/ButtonGroup'
import { UserAddressType } from 'types'
import HeaderThree from 'components/Headers/HeaderThree'
import Link from 'components/Link'
import Modal from 'components/Modal'
import Layout from 'UserApp/components/Layout'
import Text from 'sharedComponents/Text'
import HeaderOne from 'sharedComponents/HeaderOne'

import IntellitagCTA from 'UserApp/components/IntellitagCTA'
import RowElement, * as Row from 'UserApp/components/Row'
import PetForm from 'UserApp/containers/Pets/Form'
import PetsList from 'UserApp/containers/Pets/List'
import AddressForm from 'UserApp/containers/Address/Form'
import ActivateIntellitag from 'UserApp/components/ActivateIntellitag'

import style from './style.module.scss'
import mapURLconvert from 'UserApp/services/mapURLconvert'
import alertIcon from './image/alertIcon.png'
import { API_URL } from 'config'
import useNotification from '../../hooks/useNotification'
import { useHistory } from 'react-router'
// import api from 'services/pet-notify-api'
import SmsPermission from 'UserApp/components/smsPermission'

PetVaultScreen.propTypes = {
  address: PropTypes.shape(UserAddressType),
  isAddingAddress: PropTypes.bool,
  isAddingPet: PropTypes.bool,
  isEditingAddress: PropTypes.bool,
  isEditingPet: PropTypes.bool,
  onClose: PropTypes.func,
  petId: PropTypes.string,
}

function PetVaultScreen(props) {
  const {
    address,
    isAddingAddress,
    isEditingAddress,
    onClose,
    petId,
    hasPetsWithouIntellitags,
    dismissIntellitagCta,
    onDismissIntellitagCta,
    user,
    location,
  } = props
  const { hasSubscription, status } = user
  // console.log(`USER DATA :: `, user)
  const showNotification = useNotification()
  const history = useHistory(); // <- Added `useHistory` hook

  useEffect(() => {
    const checkSmsConsent = () => {
      // Check if user has SMS authorization
      if ((!user?.smsAuthorization) || (user?.smsAuthorization?.authorized===true)) {
        return;
      }
      
      const storedData = JSON.parse(localStorage.getItem('hasVisitedSmsConsent'));

      // Check if data exists and if it's still valid
      if (storedData && new Date().getTime() < storedData.expiry) {
        return; // If valid, do nothing
      }

      // If not valid or doesn't exist, set new entry and redirect
      const oneHourFromNow = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      localStorage.setItem(
        'hasVisitedSmsConsent',
        JSON.stringify({ value: true, expiry: oneHourFromNow })
      );
      history.push('/sms-consent');
    };

    checkSmsConsent();
  }, [history]);

  function handleMarkPetAsSafe(petId) {
    const markPetAsSafe = async () => {
      try {
        const response = await fetch(`${API_URL}/api/pets/petIsSafe/${petId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to mark the pet as safe.');
        }
  
        const result = await response.json();
        // console.log('Pet marked as safe:', result);
        setShowMarkPetSafeConfirmation(false)
        window.location.reload();
        // Optionally, you can update state here to reflect that the pet has been marked as safe.
      } catch (error) {
        console.error('Error marking the pet as safe:', error);
      }
    };
  
    markPetAsSafe();
  }
  

  const [petLocations, setPetLocations] = useState([])
  const [showMap, setShowMap] = useState(false)
  const [showMarkPetSafeConfirmation, setShowMarkPetSafeConfirmation] =
  useState(false)

  function handleConfirmMarkPetAsSafe() {
    setShowMarkPetSafeConfirmation(true)
  }

  function handleCancelMarkPetAsSafe() {
    setShowMarkPetSafeConfirmation(false)
  }

  const { activated } = queryString.parse(location.search)

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const userId = user?.id;
        const response = await fetch(`${API_URL}/api/users/lostPetLocations/${userId}`);

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const result = await response.json();
        setPetLocations(result.data);
        setShowMap(true);
      } catch (error) {
        console.error('Error fetching lost pet locations:', error);
        throw [];
      }
    };

    fetchLocations();
  }, [user?.pets]);

  function formatMobileNumber(number) {
    return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }

  // console.log(`PET LOCATIONS :: `, petLocations)

  return (
    <Layout title='Pet Vault'>
        {
        showMap && (petLocations?.length > 0) && (
          <div className={style.lostPets}>
              <HeaderThree className={style.header}>Lost Pet Alert 
                <img 
                  src={alertIcon} 
                  alt="Alert_Icon" 
                  className={style.icon} 
                />
              </HeaderThree>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              {/* <thead>
                <tr style={ {width: '100%'}}>
                    <th style={{  backgroundColor: '#f4f4f4', borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left', marginRight: '20px' }}>Pet Name</th>
                    <th style={{  backgroundColor: '#f4f4f4', borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left' }}>Location URL</th>
                  </tr>
                </thead> */}
                <tbody>
                {
                  petLocations.map((pet) => (
                    pet.location.status === 'LOST_PET_FOUND' && (
                      <tr key={pet.petId}>
                        {/* <td style={{  borderBottom: '1px solid #ddd', padding: '8px' , fontWeight:'bold' }}>Found Pet:</td> */}
                        <td style={{  borderBottom: '1px solid #ddd', padding: '8px' , marginRight: '20px', fontWeight:'bold' }}>Found Pet: {pet.name}</td>
                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px', display: 'flex', alignItems: 'center' }}>
                          {/* <p style={ {marginLeft: '50px', fontWeight:'bold', marginLeft:'50px'}}>Pet's Rescuer:</p> */}
                          <p style={ {marginLeft: '50px', fontWeight:'bold', marginRight:'50px'}}>Pet's Rescuer: {pet.rescuerName}</p>
                          <p style={ {marginLeft: '50px', fontWeight:'bold', marginRight: '50px'}}>{formatMobileNumber(pet.rescuerPhone)}</p>
                          <a style={{textDecoration: 'none', color:'#21a3c9', fontWeight:'bold'}} href={pet.location.url} target="_blank" rel="noreferrer">{pet.location.url}</a>
                          <Btn style={ {marginLeft: '50px', marginBottom:'5px'}} onClick={handleConfirmMarkPetAsSafe}>
                            Confirm {pet.name} is safe
                          </Btn>
                        </td>
                        {/* <td style={{ borderBottom: '1px solid #ddd', padding: '8px'}}>
                          <Btn onClick={handleConfirmMarkPetAsSafe}>
                            Confirm {pet.name} is safe
                          </Btn>
                        </td> */}
                        {showMarkPetSafeConfirmation && (
                          <Mod onClose={handleCancelMarkPetAsSafe} variant='small'>
                            <Header as='h2' size='xlarge' mb='4'>
                              Are you sure?
                            </Header>
                            <Txt mb='4'>
                              Once you mark your pet as safe, you won&apos;t be able to access the
                              recovery information again.
                            </Txt>
                              <ButtonGroup>
                                <Button
                                  type='button'
                                  variant='hollow'
                                  onClick={handleCancelMarkPetAsSafe}
                                >
                                  Cancel
                                </Button>
                                <Button type='button' onClick={()=>handleMarkPetAsSafe(pet.petId)}>
                                  Yes, I&apos;m sure
                                </Button>
                              </ButtonGroup>
                          </Mod>
                        )}
                      </tr>
                      )
                    ))}
                </tbody>
              </table>
            </div>
          )
      }
      <div className={style.wrapper}>
        <div className={style.pets}>
          <HeaderThree className={style.header}>Your pets</HeaderThree>
          <PetsList />

          <Button to='/pets/new' small>
            Add Pet
          </Button>
        </div>
        {/* {(hasPetsWithouIntellitags || !hasSubscription) &&
          !dismissIntellitagCta && (
            <div className={style.intellitagCta}>
              <IntellitagCTA
                onClose={onDismissIntellitagCta}
                hasSubscription={hasSubscription}
                status={status}
              />
            </div>
          )} */}
        <div className={style.home}>
          <HeaderThree
            className={classNames(style.header, style.addressHeader, {
              [style.hasAddress]: !!address,
            })}
          >
            Where your pets live
          </HeaderThree>
          {address ? (
            <RowElement className={style.row}>
              <Row.Value className={style.addressValue}>
                {address.address}
              </Row.Value>
              <Row.Button>
                <Link to='/address/edit'>Edit</Link>
              </Row.Button>
            </RowElement>
          ) : (
            <Button to='/address/new' className={style.button} small>
              Add address
            </Button>
          )}
        </div>
      </div>

      {activated && (
        <Modal onClose={onClose}>
          {() => (
            <div className={style.activated}>
              <HeaderOne>Welcome to PetNotify</HeaderOne>
              <Text className={style.activatedText}>
                To finalize the Intellitag activation process, upload and click
                on your pet's photo, to complete their Intellitag Lost Pet
                Instructions. There is no limit to the amount of information you
                can include, ensuring your pet's quick and safe return home.
              </Text>
              <Button onClick={onClose}>Continue to Pet Vault</Button>
            </div>
          )}
        </Modal>
      )}

      {(isEditingAddress || isAddingAddress) && (
        <Modal onClose={onClose}>
          {({ onChangedView }) => (
            <AddressForm
              onClose={onClose}
              isEditing={isEditingAddress}
              onChangedView={onChangedView}
            />
          )}
        </Modal>
      )}

      <Switch>
        <Route
          path='/pets/activate-intellitag'
          children={(props) => (
            <Modal onClose={onClose}>
              {() => <ActivateIntellitag {...props} />}
            </Modal>
          )}
        />
        <Route
          path='/pets/:id'
          children={() => (
            <Modal onClose={onClose} enableClickOffToClose={false}>
              {({ onChangedView }) => (
                <PetForm
                  onClose={onClose}
                  id={petId}
                  onChangedView={onChangedView}
                />
              )}
            </Modal>
          )}
        />
        <Route
          path='/pets/new'
          children={() => (
            <Modal onClose={onClose} enableClickOffToClose={false}>
              {({ onChangedView }) => (
                <PetForm onClose={onClose} onChangedView={onChangedView} />
              )}
            </Modal>
          )}
        />
        <Route
          path='/sms-consent'
          children={() => (
            <Modal onClose={onClose} enableClickOffToClose={false}>
              {() => (
                <SmsPermission />
              )}
            </Modal>
          )}
        />
      </Switch>
    </Layout>
  )
}

export default PetVaultScreen
