import React, { useMemo, useEffect } from 'react'
import {
  Header,
  Label,
  FieldGroup,
  Dropdown,
  Input,
  Text,
  Button,
  Loader,
  Fields,
} from '@pet-notify/ui'
import { useSelector } from 'react-redux'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import type { ReplaceIntellitagOrder } from 'UserApp/services/replace-intellitag-orders/types'
import INTELLITAG_SIZE_OPTIONS from 'lib/intellitag-size-options'
import PhoneInput from '../PhoneInput'
import { selectPets } from 'UserApp/store/pets/selectors'
import { useUpdateReplaceIntellitagOrder } from 'UserApp/hooks/api/replace-intellitag-order'
import style from '../style.module.scss'
import { ErrorText } from 'components/Forms'

const schema = yup
  .object({
    intellitagId: yup.string().transform((value) => (value === null ? '' : value)).required('Intellitag is required'),
    meta: yup.object({
      weight: yup.string().transform((value) => (value === null ? '' : value)).required('Pet weight is required'),
      phone: yup.string(),
      size: yup.string().transform((value) => (value === null ? '' : value)).required('Size is required'),
      nameOnTag: yup
        .string()
        .when('size', (size, schema) => {
          return schema.max(
            size === 'small' ? 12 : 15,
            'Name must not be over ${max} characters long',
          )
        })
        .transform((value) => (value === null ? '' : value))
        .required('Intellitag engraving is required'),
    }),
  })
  .required()

type FormData = {
  intellitagId: string
  meta: {
    nameOnTag: string
    size: string
    phone: string
    weight: string
  }
}

type Props = {
  onSuccess: () => void
  replaceIntellitagOrder: ReplaceIntellitagOrder
}

function IntellitagForm({ replaceIntellitagOrder, onSuccess }: Props) {
  const pets = useSelector(selectPets)
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      intellitagId: replaceIntellitagOrder.intellitagId,
      meta: replaceIntellitagOrder.meta,
    },
    resolver: yupResolver(schema),
  })

  const updateMutation = useUpdateReplaceIntellitagOrder(
    replaceIntellitagOrder.id,
  )
  const intellitagId = useWatch({ name: 'intellitagId', control })

  useEffect(() => {
    if (intellitagId) {
      const pet = pets.find((p) =>
        p.intellitags.find((i) => i.id === Number(intellitagId)),
      )
      if (pet) {
        const { nameOnTag, tagSize, weight, phone } = pet
        reset({
          intellitagId,
          meta: {
            nameOnTag,
            size: tagSize,
            weight,
            phone,
          },
        })
      }
    }
  }, [pets, intellitagId])

  const sizeOptions = INTELLITAG_SIZE_OPTIONS.map((option) => ({
    ...option,
    label: `${option.label} (${option.subTitle})`,
  }))

  const intellitagOptions = useMemo(() => {
    if (pets) {
      return pets.reduce((acc, pet) => {
        for (const intellitag of pet.intellitags) {
          acc.push({
            value: intellitag.id,
            label: `${pet.name} - ${intellitag.number}`,
          })
        }
        return acc
      }, [])
    }
  }, [pets])

  function onSubmit(data: FormData) {
    updateMutation.mutate(data, {
      onSuccess,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header className={style.header}>Intellitag replacement</Header>
      <Fields>
        <FieldGroup>
          <Label htmlFor='intellitagId'>
            Which Intellitag are you replacing?
          </Label>
          <Dropdown
            id='intellitagId'
            options={intellitagOptions}
            {...register('intellitagId')}
          />
        </FieldGroup>

        {errors.intellitagId && (
          <Text variant='error'>{errors.intellitagId.message}</Text>
        )}

        <FieldGroup>
          <Label htmlFor='meta-size'>Intellitag size</Label>
          <Dropdown
            id='intellitag-tagSize'
            options={sizeOptions}
            {...register('meta.size')}
          />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='meta-weight'>Pet weight (in pounds)</Label>
          <Input type='text' id='meta-weight' {...register('meta.weight')} />
        </FieldGroup>

        {errors.meta?.weight && (
          <Text variant='error'>{errors.meta.weight.message}</Text>
        )}

        <FieldGroup>
          <Label htmlFor='meta-nameOnTag'>Intellitag engraving</Label>
          <Text className={style.subTitle}>
            What name do you want engraved on your Intellitag?{' '}
            {watch('meta.size') === 'small' ? 12 : 15} character limit.
          </Text>

          <Input id='name' {...register('meta.nameOnTag')} />
        </FieldGroup>

        {errors.meta?.nameOnTag && (
          <ErrorText>{errors.meta.nameOnTag.message}</ErrorText>
        )}

        <FieldGroup>
          <Label htmlFor='meta-phone'>Mobile phone number</Label>
          <Text className={style.subTitle}>
            This number is engraved on your Intelitag and is optional for
            Intellitag will call, email, and text you when your lost pet is
            found.
          </Text>
          <PhoneInput
            id='meta-phone'
            placeholder='Optional'
            name='meta.phone'
            control={control}
          />
        </FieldGroup>

        <Text className={style.disclaimer}>
          Your pet&apos;s Intellitag ID is directly tied to your Pet Notify user
          account and will not provide lost pet communications if gifted, or
          transferred to another party.
        </Text>
      </Fields>
      {updateMutation.isLoading ? (
        <Loader />
      ) : (
        <Button type='submit'>Continue</Button>
      )}
    </form>
  )
}

export default IntellitagForm
