import React, {useEffect, useState} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import classNames from 'classnames';
import style from './style.module.scss'
import Cookies from 'js-cookie';
import {Loader} from '@pet-notify/ui'
import logo from './images/logo.svg';
import useNotification from '../../hooks/useNotification'
import { useDispatch } from 'react-redux'; // Import useDispatch
import { fetch as fetchUser  } from '../../store/user/actions';
// import api from 'services/pet-notify-api'


function MobileToWeb() {
  // console.log(`MobileToWeb function running`)

  const {setNotification} = useNotification()
  const history = useHistory()
  const dispatch = useDispatch();
  const [isTokenSet, setIsTokenSet] = useState(false)
  const {userTo} = useParams()

  useEffect(() => {
    // console.log(`TOKEN userTo :: `,userTo)

    setNotification('You are being redirected')

    if (userTo) {
      // Save userT as a cookie named 'token' with 1 year expiry
      Cookies.set('token', userTo, {
        expires: 365,
      });
    
      // console.log('Token saved to cookies:', userTo);
      setIsTokenSet(true)

      dispatch(fetchUser ());
    } else {
      console.error('No token provided to save.');
      setIsTokenSet(false)
    }
  }, [])

  useEffect(() => {
    if (isTokenSet) {
      // Redirect to the next page after 3 seconds
      const timer = setTimeout(() => {
        // console.log('Redirecting to /pets/activate-intellitag...');
        history.replace('/pets/activate-intellitag');
      }, 1500);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isTokenSet,history]); 

  // console.log(`TOKEN set :: `,userTo)

  return (
    <>
      {!isTokenSet && 
      <div>
        <img
          src={logo}
          className={classNames(style.wrapper)}
          alt='Pet Notify'
          height='37'
          />
        <Loader/>
      </div>
      }
    </>
  )
}

export default MobileToWeb